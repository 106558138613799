import React, { useState } from "react";
import "./Portfolio.css";

// import Menu from "./Menu";
// import { RiGithubLine, RiLink } from "react-icons/ri";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import img1 from "../../assets/tranvia/tranvia1.jpeg";
import img2 from "../../assets/tranvia/tranvia2.jpeg";
import img3 from "../../assets/tranvia/tranvia3.jpeg";
import img4 from "../../assets/tranvia/tranvia4.jpeg";
import img5 from "../../assets/tranvia/tranvia5.jpeg";
import img6 from "../../assets/tranvia/tranvia6.jpeg";
import img7 from "../../assets/tranvia/tranvia7.jpeg";
import img8 from "../../assets/tranvia/tranvia8.jpeg";
import img9 from "../../assets/tranvia/tranvia9.jpeg";
import img10 from "../../assets/tranvia/tranvia10.jpeg";
import img11 from "../../assets/tranvia/tranvia11.jpeg";
import img12 from "../../assets/tranvia/tranvia12.jpeg";
import img13 from "../../assets/tranvia/tranvia13.jpeg";
import img14 from "../../assets/tranvia/tranvia14.jpeg";
import img15 from "../../assets/tranvia/tranvia15.jpeg";
import img16 from "../../assets/tranvia/tranvia16.jpeg";
import img17 from "../../assets/tranvia/tranvia17.jpeg";
import img18 from "../../assets/tranvia/tranvia18.jpeg";

const Portfolio = () => {
  const [t, i18n] = useTranslation("global");
  const Menu = [
    {
      id: 1,
      image: img16,
      title: t("portfolio.titles.id-16"),
      category: [
        t("portfolio.tags.trainway"),
      ],
    },
    {
      id: 2,
      image: img17,
      title: t("portfolio.titles.id-17"),
      category: [
        t("portfolio.tags.trainway"),
        t("portfolio.tags.electromechanical"),
      ],
    },
    {
      id: 3,
      image: img18,
      title: t("portfolio.titles.id-18"),
      category: [
        t("portfolio.tags.trainway"),
        t("portfolio.tags.electromechanical"),
      ],
    },
    {
      id: 4,
      image: img1,
      title: t("portfolio.titles.id-1"),
      category: [
        t("portfolio.tags.trainway"),
        t("portfolio.tags.electromechanical"),
      ],
      // url: "https://memory-app.gregsithole.com",
      // repositoryUrl: "https://github.com/GregSithole/memories-project"
    },
    {
      id: 5,
      image: img2,
      title: t("portfolio.titles.id-2"),
      category: [t("portfolio.tags.trainway"), t("portfolio.tags.informatic")],
      // url: "https://cryptoverse.gregsithole.com",
      // repositoryUrl: "https://github.com/GregSithole/crypto-app"
    },
    {
      id: 6,
      image: img3,
      title: t("portfolio.titles.id-3"),
      category: [t("portfolio.tags.trainway"), t("portfolio.tags.informatic")],
      // url: "https://travel.gregsithole.com",
      // repositoryUrl: "https://github.com/GregSithole/travel-advisor"
    },
    {
      id: 7,
      image: img4,
      title: t("portfolio.titles.id-4"),
      category: [
        t("portfolio.tags.trainway"),
        t("portfolio.tags.electromechanical"),
      ],
    },
    {
      id: 8,
      image: img5,
      title: t("portfolio.titles.id-5"),
      category: [t("portfolio.tags.trainway"), t("portfolio.tags.informatic")],
    },
    {
      id: 9,
      image: img6,
      title: t("portfolio.titles.id-6"),
      category: [
        t("portfolio.tags.trainway"),
        t("portfolio.tags.electromechanical"),
      ],
    },
    {
      id: 10,
      image: img7,
      title: t("portfolio.titles.id-7"),
      category: [t("portfolio.tags.trainway"), t("portfolio.tags.informatic")],
    },
    {
      id: 11,
      image: img8,
      title: t("portfolio.titles.id-8"),
      category: [t("portfolio.tags.support"), t("portfolio.tags.informatic")],
    },
    {
      id: 12,
      image: img9,
      title: t("portfolio.titles.id-9"),
      category: [t("portfolio.tags.support"), t("portfolio.tags.informatic")],
    },
    {
      id: 13,
      image: img10,
      title: t("portfolio.titles.id-10"),
      category: [t("portfolio.tags.support"), t("portfolio.tags.informatic")],
    },
    {
      id: 14,
      image: img11,
      title: t("portfolio.titles.id-11"),
      category: [t("portfolio.tags.trainway"), t("portfolio.tags.informatic")],
    },
    {
      id: 15,
      image: img12,
      title: t("portfolio.titles.id-12"),
      category: [t("portfolio.tags.support"), t("portfolio.tags.informatic")],
    },
    {
      id: 16,
      image: img13,
      title: t("portfolio.titles.id-13"),
      category: [t("portfolio.tags.secretariat")],
    },
    {
      id: 17,
      image: img14,
      title: t("portfolio.titles.id-14"),
      category: [t("portfolio.tags.trainway")],
    },
    {
      id: 18,
      image: img15,
      title: t("portfolio.titles.id-15"),
      category: [t("portfolio.tags.trainway")],
    },
  ];
  const [items, setItems] = useState(Menu);
  const [activeFilter, setActiveFilter] = useState(0);
  const filterItems = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category.includes(categoryItem);
    });

    setItems(updatedItems);
  };

  return (
    <section className="portfolio container section" id="portfolio">
      <h2 className="section__title">{t("portfolio.titles.title")}</h2>

      <div className="portfolio__filters">
        <span
          className={
            activeFilter === 0
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            setItems(Menu);
            setActiveFilter(0);
          }}
        >
          {t("portfolio.menu.all")}
        </span>
        <span
          className={
            activeFilter === 1
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems(t("portfolio.tags.informatic"));
            setActiveFilter(1);
          }}
        >
          {t("portfolio.menu.informatic")}
        </span>
        <span
          className={
            activeFilter === 2
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems(t("portfolio.tags.electromechanical"));
            setActiveFilter(2);
          }}
        >
          {t("portfolio.menu.electromechanical")}
        </span>
        {/* <span className={activeFilter === 3 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("Angular"); setActiveFilter(3) }}>
                    Angular
                </span>
                <span className={activeFilter === 4 ? 'portfolio__item portfolio__item-active' : 'portfolio__item'} onClick={() => { filterItems("React"); setActiveFilter(4) }}>
                    React
                </span> */}
      </div>

      <div className="portfolio__container grid">
        {items.map((elem) => {
          const { id, image, title, category, url, repositoryUrl } = elem;

          return (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="portfolio__card"
              key={id}
            >
              <div className="portfolio__thumbnail">
                <img src={image} alt="" className="portfolio__img" />
                <div className="portfolio__mask"></div>
              </div>

              <span className="portfolio__category">{category.join(", ")}</span>
              <h3 className="portfolio__title">{title}</h3>
              {/* <a href={url} target="_blank" rel="noreferrer" className="portfolio__button">
                                <RiLink className="portfolio__button-icon" />
                            </a>
                            <a href={repositoryUrl} target="_blank" rel="noreferrer" className="portfolio__github-button">
                                <RiGithubLine className="portfolio__button-icon" />
                            </a> */}
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
