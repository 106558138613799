import React from "react";
import "./Services.css";
import Image1 from "../../assets/computer.png";
import Image2 from "../../assets/data-recovery.png";
import Image3 from "../../assets/settings.png";
import Image4 from "../../assets/data-management.png";
import Image5 from "../../assets/evaluation.png";
import Image6 from "../../assets/repair.png";
import Image7 from "../../assets/tramway.png";
import { useTranslation } from "react-i18next";

const Services = () => {
  const [t, i18n] = useTranslation("global");

  const data = [
    {
      id: 1,
      image: Image1,
      title: t("services.all.first.title"),
      description: t("services.all.first.desc"),
    },
    {
      id: 2,
      image: Image2,
      title: t("services.all.second.title"),
      description: t("services.all.second.desc"),
    },
    {
      id: 3,
      image: Image3,
      title: t("services.all.third.title"),
      description: t("services.all.third.desc"),
    },
    {
      id: 4,
      image: Image4,
      title: t("services.all.fourth.title"),
      description: t("services.all.fourth.desc"),
    },
    {
      id: 5,
      image: Image5,
      title: t("services.all.fifth.title"),
      description: t("services.all.fifth.desc"),
    },
    {
      id: 6,
      image: Image6,
      title: t("services.all.sixth.title"),
      description: t("services.all.sixth.desc"),
    },
    {
      id: 7,
      image: Image7,
      title: t("services.all.seventh.title"),
      description: t("services.all.seventh.desc"),
    },
    {
      id: 8,
      image: Image7,
      title: t("services.all.eighth.title"),
      description: t("services.all.eighth.desc"),
    },
    {
      id: 9,
      image: Image7,
      title: t("services.all.ninth.title"),
      description: t("services.all.ninth.desc"),
    }
  ];
  return (
    <section className="services container section" id="services">
      <h2 className="section__title">{t("services.title")}</h2>

      <div className="services__container grid">
        {data.map(({ id, image, title, description }) => {
          return (
            <div className="services__card" key={id}>
              <img src={image} alt="" className="services__img" width="80" />

              <h3 className="services__title">{t(title)}</h3>
              <p className="services__description">{description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
