import React, { useState } from "react";
import "./Resume.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Button from "./Button";

//import WorkExperience from "./WorkExperience";
import { useTranslation } from "react-i18next";

const Resume = () => {
  const [t, i18n] = useTranslation("global");
  const [tabIndex, setTabIndex] = useState(0);

  const WorkExperience = [
    {
      id: 1,
      title: t("experience.jobs.first.title"),
      company: t("experience.jobs.first.company"),
      yearsActive: t("experience.jobs.first.date"),
      information: [
        t("experience.jobs.first.info.first"),
        t("experience.jobs.first.info.second"),
        t("experience.jobs.first.info.third"),
      ],
    },
    {
      id: 2,
      title: t("experience.jobs.second.title"),
      company: t("experience.jobs.second.company"),
      yearsActive: t("experience.jobs.second.date"),
      information: [
        t("experience.jobs.second.info.first"),
        t("experience.jobs.second.info.second"),
      ],
    },
    {
      id: 3,
      title: t("experience.jobs.third.title"),
      company: t("experience.jobs.third.company"),
      yearsActive: t("experience.jobs.third.date"),
      information: [
        t("experience.jobs.third.info.first"),
        t("experience.jobs.third.info.second"),
        t("experience.jobs.third.info.third"),
      ],
    },
    {
      id: 4,
      title: t("experience.jobs.fourth.title"),
      company: t("experience.jobs.fourth.company"),
      yearsActive: t("experience.jobs.fourth.date"),
      information: [
        t("experience.jobs.fourth.info.first"),
        t("experience.jobs.fourth.info.second"),
      ],
    },
  ];

  return (
    <section className="resume container section" id="resume">
      <h2 className="section__title">{t("experience.title")}</h2>

      <div className="resume__container">
        <Tabs
          className="tabs"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          selectedTabClassName={"is-active"}
          selectedTabPanelClassName={"is-active"}
        >
          <TabList className="tab__list">
            {WorkExperience.map((experience, index) => {
              const { id, company } = experience;
              return (
                <Tab className="tab" key={`company-${id}`}>
                  <Button>{company}</Button>
                </Tab>
              );
            })}
          </TabList>

          {WorkExperience.map((experience) => {
            const { id, company, yearsActive, title, information } = experience;
            return (
              <TabPanel className="tab__panel" key={`panel-${id}`}>
                <h2 className="tab__panel-title">
                  {title} @ {company}
                </h2>
                <p className="tab__panel-subtitle">{yearsActive}</p>
                <ul className="tab__panel-list">
                  {information.map((info, index) => {
                    return <li key={`info-${index}`}>{info}</li>;
                  })}
                </ul>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default Resume;
