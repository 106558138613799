import React from "react";
import "./Home.css";
import Me from "../../assets/me.png";
import Logo from "../../assets/profile.jpg";
import HeaderSocials from "./HeaderSocials";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";
import Spain from "../../assets/spain.png";
import France from "../../assets/france.png";
import UK from "../../assets/united-kingdom.png";

// import ScrollDown from './ScrollDown';
// import Shapes from './Shapes';

const Home = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <section className="home container" id="home">
      <div className="intro">
        <img
          src={Me}
          alt=""
          style={{ borderRadius: "50%" }}
          className="home__img"
          width="120"
        />
        <h1 className="home__name">Bernardo Larios</h1>
        <span className="home__education">{t("home.systems-engineer")}</span>
        <br />
        <span className="home__education">&</span>
        <br />
        <span className="home__education">{t("home.electromechanical-engineer")}</span>

        <HeaderSocials />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => i18n.changeLanguage("es")}
            style={{
              backgroundColor: "transparent",
              margin: "10px 10px 10px 10px",
            }}
          >
            <img src={Spain} alt="ES" width={40} height={40} />
          </button>
          <button
            onClick={() => i18n.changeLanguage("en")}
            style={{ backgroundColor: "transparent" }}
          >
            <img src={UK} alt="EN" width={40} height={40} />
          </button>
          <button
            onClick={() => i18n.changeLanguage("fr")}
            style={{
              backgroundColor: "transparent",
              margin: "10px 10px 10px 10px",
            }}
          >
            <img src={France} alt="FR" width={40} height={40} />
          </button>
        </div>
        <br />
        <a href="#contact" className="btn">
          {" "}
          {t("home.contact")}
        </a>

        <FloatingWhatsApp
          phoneNumber="573004567312"
          text={t("whatsapp.text")}
          accountName="Bernardo Larios"
          avatar={Logo}
          chatMessage={t("whatsapp.chatMessage")}
          placeholder={t("whatsapp.placeholder")}
          statusMessage={t("whatsapp.statusMessage")}
          allowClickAway={true}
          allowEsc={true}
          notification={true}
          notificationSound={true}
        />

        {/* <ScrollDown /> */}
      </div>
      {/* <Shapes /> */}
    </section>
  );
};

export default Home;
