import React from 'react'
import { RiFireLine, RiCupLine, RiGroupLine, RiTrophyLine } from 'react-icons/ri';
import { useTranslation } from "react-i18next";

const AboutBox = () => {
    const [t, i18n] = useTranslation("global");
    return (
        <div className="about__boxes grid" 
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <div className="about__box">
                <RiFireLine className='about__icon' />

                <div>
                    <h3 className="about__title">25</h3>
                    <span className="about__subtitle">{t("about.box-1")}</span>
                </div>
            </div>
{/* 
            <div className="about__box">
                <RiCupLine className='about__icon' />

                <div>
                    <h3 className="about__title">31</h3>
                    <span className="about__subtitle">Github Repositories</span>
                </div>
            </div>

            <div className="about__box">
                <RiGroupLine className='about__icon' />

                <div>
                    <h3 className="about__title">3</h3>
                    <span className="about__subtitle">Launched Projects</span>
                </div>
            </div> */}

            <div className="about__box">
                <RiTrophyLine className='about__icon' />

                <div>
                    <h3 className="about__title">+1000</h3>
                    <span className="about__subtitle">{t("about.box-4")}</span>
                </div>
            </div>
        </div>
    )
}

export default AboutBox