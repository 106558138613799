import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { SiHashnode } from 'react-icons/si'

const HeaderSocials = () => {
    return (
        <div className='home__socials' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <a href='https://wa.me/573004567312' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaWhatsapp />
            </a>

            <a href='https://www.linkedin.com/in/bernardo-larios-31384027/' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaLinkedinIn />
            </a>

            <a href='https://www.facebook.com/BernardoLarios' className='home__social-link' target='_blank' rel='noreferrer'>
                <FaFacebookF />
            </a>

            <a href='https://www.instagram.com/bernardolarios71/' className='home__social-link' target='__blank' rel='noreferrer'>
                <FaInstagram />
            </a>
        </div>
    );
};

export default HeaderSocials;
