import React from "react";
import "./About.css";
import Image from "../../assets/profile.jpg";
import Resume from "../../assets/bernardo_resume.pdf";
import AboutBox from "./AboutBox";
import { useTranslation } from "react-i18next";

const About = () => {
  const [t, i18n] = useTranslation("global");
  const downloadResume = async () => {
    window.open(Resume, "_blank");
  };

  return (
    <section className="about container section" id="about">
      <h2 className="section__title">{t("about.title")}</h2>

      <div className="about__container grid">
        <img
          src={Image}
          alt=""
          style={{ borderRadius: "50%" }}
          className="about__img"
        />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">{t("about.parr-1")}</p>
            <p>{t("about.parr-2")}</p>
            <br />
            <p>{t("about.parr-3")}</p>
            <br />
            <p>{t("about.parr-4")}</p>
            <br />
            <p>{t("about.parr-5")}</p>
            <br />
            <p>{t("about.parr-6")}</p>
            {/* <ul className="about__list">
              <li>JavaScript (ES6+)</li>
              <li>TypeScript</li>
              <li>React</li>
              <li>Node.js</li>
              <li>Postgres SQL</li>
              <li>NestJS</li>
            </ul> */}
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button className="btn" onClick={downloadResume}>
                Curriculum
              </button>
            </div>
          </div>

          {/* <div className="about__skills grid">
                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Development</h3>
                                <span className="skills__number">90%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage development">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">UI/UX Design</h3>
                                <span className="skills__number">80%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage ui__design">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Photography</h3>
                                <span className="skills__number">60%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage photography">

                                </span>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <AboutBox />
    </section>
  );
};

export default About;
